<template>
  <div id="app">
    <img alt="Argonne logo" src="./assets/argonne.jpg" class="anllogo">
    
    <img alt="PCD logo" src="./assets/pcd.png" class="pcdlogo">
    <Grib/>
  </div>
</template>

<script>
import Grib from './components/Grib.vue'

export default {
  name: 'App',
  components: {
    Grib
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
.anllogo {
  zoom:25%;
  padding-right:200px;
}.pcdlogo {
  zoom:50%;
}
</style>
