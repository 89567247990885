<template>
  <div>
    <div class="spacer40"></div>
    <h4>Update Status: {{ overallStatus }}</h4>
    
    <div class="spacer20"></div>
    <div >Forecast Status: {{ gribStatus }}</div>
    <div class="spacer10"></div>
    <b-button v-on:click="clickGetGribs">Download Forecast Files</b-button>

    <div class="spacer20"></div>
    <div>Observations Status: {{ metarStatus }}</div>
    <div class="spacer10"></div>
    <b-button v-on:click="clickGetMetars">Download Observations</b-button>
  </div>
</template>

<script>
//import { BButton } from "bootstrap-vue";
//import FormlyInject from "./FormlyInject.vue";
//import FormlyAll from "./FormlyAll.vue";
// import FormlySample from './FormlySample.vue';
//import MyComponent from './MyComponent.vue';
import axios from "axios";

export default {
  name: "Grib",
  data() {
    return {
      gribStatus: "no forecast status",
      metarStatus: "no observations status",
      overallStatus: "no update status",
      //baseUrl: "https://localhost:5001",
      // baseUrl: "./",
      baseUrl: "",
    };
  },
  components: {},
  methods: {
    statusTimer() {
      this.fetchStatus();
      setTimeout(this.statusTimer, 1000 * 60 * 1);
    },
    clickGetGribs() {
      var url1 = this.baseUrl + "WeatherForecast/GetGribs";
      axios({ method: "get", url: url1, responseType: "arraybuffer" })
        .then((response) => {
            var blob = new Blob([response.data])
          var url = window.URL.createObjectURL(blob);
          var a = document.createElement("a");
          a.href = url;
          a.setAttribute("download", "gribs.zip");
          document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
          a.click();
        })
        .catch((err) => console.log(err));
    },
    clickGetMetars() {
      var zulu = this.metarStatus.substring(0,3);
      var url1 = this.baseUrl + "WeatherForecast/GetMetars";
      axios({ method: "get", url: url1, responseType: "arraybuffer" })
        .then((response) => {
            var blob = new Blob([response.data])
          var url = window.URL.createObjectURL(blob);
          var a = document.createElement("a");
          a.href = url;
          a.setAttribute("download", zulu+".txt");
          document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
          a.click();
        })
        .catch((err) => console.log(err));
    },
    async fetchStatus() {
      const res = await fetch(this.baseUrl + "WeatherForecast/GetStatus");
      const data = await res.json();
      this.gribStatus = data.gribStatusText;
      this.metarStatus = data.metarStatusText;
      this.overallStatus = data.overallStatusText;
    },
    //     fetchStatus(){
    //         // Simple GET request using fetch
    //   fetch("https://localhost:5001/WeatherForecast/GetStatus")
    //     // .then(response => response.json())
    //     .then(data => (
    //         this.gribStatus = data.response));
    //     }
  },
  created() {
    this.fetchStatus();
  },
  mounted() {
    setTimeout(this.statusTimer, 1000 * 60 * 1);
  },
};
</script>

<style>
.spacer20 {
  padding-bottom:20px;
}
.spacer40 {
  padding-bottom:40px;
}
.spacer10 {
  padding-bottom:10px;
}
</style>
